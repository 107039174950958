import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"cmsusers",
        "id":"cmsuser_id",
        "subidname":"cmsuser_name",
        "options":["edit","delete","insert"],
        "nameSingle":"cms user",
        "nameMultiple":"cms users",
      
        "fields":{
            "field1":{
                "name":"Name",
                "field":"cmsuser_name",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            "field2":{
                "name":"Email",
                "field":"cmsuser_email",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            "field3":{
                "name":"Password",
                "field":"cmsuser_password",
                "type":"PasswordInput",
                
                "list":false
            },
            "field4":{
                "name":"Type",
                "field":"cmsuser_type",
                "type":"DropDown",
                "options": {
                    "regular":"Regular",
                    "editor":"Editor"
                },

                "required":true,
                
                "list":true
            }
        }
    }
}